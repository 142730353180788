export enum CELConditionOperator {
  OR = "||",
  AND = "&&",
}

function applyStringOperatorFunction(
  func: string,
  field: string,
  value: any,
  caseSensitive = false
) {
  const values = Array.isArray(value) ? value : [value];

  return `[${format(values.map(CELOperators.toString), caseSensitive)}].exists(x, ${formatCEL(
    field,
    caseSensitive
  )}.${func}(${formatCEL("x", caseSensitive)}))`;
}

function applyListOperatorFunction(
  func: string,
  field: string,
  values: any[],
  valueAccessor?: (item: string) => string
) {
  return `${field}.exists(x, ${valueAccessor ? valueAccessor("x") : "x"}.${func}([${values
    .map(CELOperators.toString)
    .map(lower)
    .join(",")}]))`;
}

export const CELOperators = {
  toString: (value: any) => `"${value.replace(/\\/g, "\\\\").replace(/"/g, '\\"')}"`,
  toDate: (value: any) => `timestamp(${CELOperators.toString(value)})`,
  search: (fields: string[], value: any) =>
    fields
      .map((field) => CELOperators.string.contains(field, [value]))
      .join(` ${CELConditionOperator.OR} `),
  common: {
    equal: (field: string, value: any) => `${field} == ${value}`,
    notEqual: (field: string, value: any) => `${field} != ${value}`,
    empty: (field: string) => CELOperators.common.equal(field, "null"),
    notEmpty: (field: string) => CELOperators.common.notEqual(field, "null"),
    exists: (field: string, emptyValue: string = '""') => `${field} != ${emptyValue}`,
    in: (field: string, values: any[]) => `${field} in [${values.join(",")}]`,
  },
  string: {
    anyOf: (field: string, values: any[], caseSensitive = false) =>
      `${formatCEL(field, caseSensitive)} in [${values
        .map((v) => format(v, caseSensitive))
        .join(",")}]`,
    contains: (field: string, values: any[], caseSensitive = false) =>
      applyStringOperatorFunction("contains", field, values, caseSensitive),
    startsWith: (field: string, value: any, caseSensitive = false) =>
      applyStringOperatorFunction("startsWith", field, value, caseSensitive),
    matchesWith: (field: string, value: any, caseSensitive = false) =>
      applyStringOperatorFunction("matchesWith", field, value, caseSensitive),
    endsWith: (field: string, value: any, caseSensitive = false) =>
      applyStringOperatorFunction("endsWith", field, value, caseSensitive),
  },
  number: {
    greater: (field: string, value: any) => `${field} > ${value}`,
    greaterOrEqual: (field: string, value: any) => `${field} >= ${value}`,
    less: (field: string, value: any) => `${field} < ${value}`,
    lessOrEqual: (field: string, value: any) => `${field} <= ${value}`,
  },
  list: {
    empty: (field: string, valueAccessor?: (item: string) => string) =>
      `!${CELOperators.list.notEmpty(field, valueAccessor)}`,
    notEmpty: (field: string, valueAccessor?: (item: string) => string) =>
      `${field}.exists(x, size(string(${valueAccessor ? valueAccessor("x") : "x"})) > 0)`,
    startsWith: (field: string, values: string[], valueAccessor?: (item: string) => string) =>
      applyListOperatorFunction("existsStartsCI", field, values, valueAccessor),
    endsWith: (field: string, values: any[], valueAccessor?: (item: string) => string) =>
      applyListOperatorFunction("existsEndsCI", field, values, valueAccessor),
    matchesWith: (field: string, values: string[], valueAccessor?: (item: string) => string) =>
      applyListOperatorFunction("existsRegexpCI", field, values, valueAccessor),
    existsIs: (field: string, values: any[], valueAccessor?: (item: string) => string) =>
      applyListOperatorFunction("existsEqualsCI", field, values, valueAccessor),
    existsContains: (field: string, values: any[], valueAccessor?: (item: string) => string) =>
      applyListOperatorFunction("existsContainsCI", field, values, valueAccessor),
  },
  date: {
    between: (field: string, start: string, end: string) => {
      return `${field} >= ${CELOperators.toDate(start)} && ${field} < ${CELOperators.toDate(end)}`;
    },
  },
};

function format(value: any, caseSensitive = false) {
  return caseSensitive ? String(value) : lower(value);
}

function formatCEL(field: string, caseSensitive = false) {
  return caseSensitive ? field : lowerCEL(field);
}

export function lower(value: any) {
  return String(value).toLowerCase();
}

export function lowerCEL(field: string) {
  return `${field}.lowerAscii()`;
}

function celNonEmptyExpr(s: string): boolean {
  if (!s) {
    return false;
  }
  return s.split("").some((c: string) => c !== "(" && c !== ")" && c !== " ");
}

export const celCombine = (op: CELConditionOperator, ...elems: string[]): string => {
  const parts: string[] = [];
  for (const e of elems) {
    if (celNonEmptyExpr(e)) {
      parts.push("(" + e + ")");
    }
  }
  if (parts.length === 0) {
    return "";
  }
  if (parts.length === 1) {
    return parts[0];
  }
  return "(" + parts.join(" " + op + " ") + ")";
};
