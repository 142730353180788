import React from "react";
import { GridActionsCellItemProps } from "@mui/x-data-grid-pro";
import { GridCustomization } from "./organisms/GridCustomization";
import { customGridClasses } from "./constants";
import { CHGridColDef } from "./types";

export const EMPTY_GRID_ACTIONS = () => [] as Array<React.ReactElement<GridActionsCellItemProps>>;
const ACTIONS_COLUMN_WIDTH = 34;
export const ACTIONS_COLUMN_CONFIG: CHGridColDef = {
  field: "actions",
  type: "actions",
  headerName: "Actions",
  headerClassName: customGridClasses.actionsHeader,
  renderHeader: () => <GridCustomization />,
  getActions: EMPTY_GRID_ACTIONS,
  width: ACTIONS_COLUMN_WIDTH,
  maxWidth: ACTIONS_COLUMN_WIDTH,
  minWidth: ACTIONS_COLUMN_WIDTH,
  flex: 0,
  resizable: false,
  hideable: false,
  disableColumnMenu: true,
  disableReorder: true,
  disableExport: true,
};

export const NEW_STYLE_ACTIONS_COLUMN_WIDTH = 80;

export const ACTIONS_COLUMN_CONFIG_NEW_STYLE: CHGridColDef = {
  ...ACTIONS_COLUMN_CONFIG,
  renderHeader: () => "Actions",
  width: NEW_STYLE_ACTIONS_COLUMN_WIDTH,
  maxWidth: NEW_STYLE_ACTIONS_COLUMN_WIDTH,
  minWidth: NEW_STYLE_ACTIONS_COLUMN_WIDTH,
};
