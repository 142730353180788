import React from "react";
import { twMerge } from "tailwind-merge";

export interface PillProps
  extends Pick<React.HTMLAttributes<HTMLDivElement>, "className" | "onClick" | "children"> {}

export function Pill({ className, ...props }: PillProps) {
  const classNames = twMerge(
    className,
    "flex flex-row items-center gap-xs rounded-2xl border border-solid border-outlines-2 pl-s pr-s pt-xxs pb-xxs cursor-pointer"
  );
  return <div className={classNames} {...props} />;
}
