import React from "react";
import { useTheme } from "shared/color-theme/useTheme";
import { ThemeProvider } from "../shared/color-theme/useTheme";

export const ThemeLoaderInner = ({ children }: { children: React.ReactNode }) => {
  useTheme();

  return children;
};

export const ThemeLoader = ({ children }: { children: React.ReactNode }) => {
  return (
    <ThemeProvider>
      <ThemeLoaderInner>{children}</ThemeLoaderInner>
    </ThemeProvider>
  );
};
