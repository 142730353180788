import { useCallback, useMemo } from "react";
import { useCanArray } from "./permissions";
import { useAppEnvironment } from "./environment";
import { INavigationItem } from "./types";
import { useHomeRouteSetting } from "./useHomeRouteSettings";

export function useAvailableNavigationItems(): {
  availableItems: INavigationItem[] | null;
  availablePaths: string[] | undefined;
  homeItem: INavigationItem | null;
  isLoading: boolean;
} {
  const { navigationItems } = useAppEnvironment();
  const { homeRoute: defaultHomeRoute } = useHomeRouteSetting();

  const visibleNavigationItems = useMemo(
    () => navigationItems.filter((item) => !item.hidden),
    [navigationItems]
  );

  const availableItems = useCanArray({
    items: visibleNavigationItems,
    getItemPermission: useCallback(
      (item: INavigationItem) => item.requiredPermissions || item.requiredPermissionsAnyOf,
      []
    ),
    isDoEither: useCallback((item: any) => !!item.requiredPermissionsAnyOf, []),
  });

  const availablePaths = useMemo(() => availableItems?.map((item) => item.path), [availableItems]);

  const homeItem = useMemo(
    () =>
      // The user might not have the permissions for the default route
      availableItems?.find((i) => i.path === defaultHomeRoute) ??
      // Select the first available item if the default route is not available
      (availableItems?.filter((link) => !link.isSpacer)?.[0] || null),
    [availableItems, defaultHomeRoute]
  );

  return {
    availableItems,
    availablePaths,
    isLoading: !!availableItems,
    homeItem,
  };
}
