import React from "react";
import { cva, VariantProps } from "class-variance-authority";
import { twMerge } from "tailwind-merge";
import { focusedInteractiveOutlineStyles } from "ds-ui/styles";
import { Chip, ChipProps } from "@mui/material";
import { Text, TextProps } from "./Text";

export type TagProps = {
  label: string;
  textProps?: TextProps;
  numerical?: boolean;
} & Omit<ChipProps, "size"> &
  VariantProps<typeof tagStyles>;

const tagStyles = cva("", {
  variants: {
    clickable: {
      true: focusedInteractiveOutlineStyles,
    },
    noWrap: {
      true: "truncate ...",
    },
    size: {
      sm: "py-xxs px-m",
      md: "p-m",
    },
  },

  defaultVariants: {
    clickable: false,
    size: "md",
  },
});

export const Tag = ({
  label,
  textProps,
  onClick,
  className,
  numerical,
  size = "sm",
  noWrap = true,
  ...rest
}: TagProps) => {
  const clickable = !!onClick;

  return (
    <Chip
      label={
        <Text variant="inherited" font={numerical ? "mono" : undefined} {...textProps}>
          {label}
        </Text>
      }
      title={label}
      sx={{
        ".MuiChip-label": {
          padding: 0,
          display: "inline-block",
          maxWidth: "100%",
        },
      }}
      onClick={onClick}
      className={twMerge(
        tagStyles({
          className,
          clickable,
          size,
          noWrap,
        })
      )}
      {...rest}
    />
  );
};
