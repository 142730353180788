import React from "react";
import { VariantProps, cva } from "class-variance-authority";
import { fixedForwardRef } from "shared";
import { twMerge } from "tailwind-merge";

export type TextVariant = VariantProps<typeof textStyles>["variant"];
export type TextProps<T extends React.ElementType = "span"> = React.ComponentProps<T> &
  VariantProps<typeof textStyles> & {
    as?: T;
    className?: string;
    children?: React.ReactNode;
    ref?: React.Ref<T>;
  };

const textStyles = cva("", {
  variants: {
    variant: {
      // 12px normal
      smallRegular: "text-s font-normal leading-s",
      // 12px medium
      smallMedium: "text-s font-medium leading-s",
      // 16px normal
      mediumRegular: "text-m font-normal leading-m",
      // 16px medium
      mediumMedium: "text-m font-medium leading-m",
      // 16px semibold
      mediumSemibold: "text-m font-semibold leading-m",
      // 20px semibold
      largeSemibold: "text-l font-semibold leading-l",
      // 20px medium
      largeMedium: "text-l font-medium leading-l",
      // 24px semibold
      xLargeSemibold: "text-xl font-semibold leading-xl",
      // 24px medium
      xLargeMedium: "text-xl font-medium leading-xl",
      // 48px bold
      XLLBold: "text-xxl font-bold leading-xxl",
      XLLRegular: "text-xxl font-normal leading-xxl",
      FourXLRegular: "text-4xl font-normal leading-4xl",
      FourXLLight: "text-4xl font-light leading-xxl",
      inherited: "",
    },
    // can be overriten by className if no color is specified
    color: {
      primary: "text-text-primary",
      secondary: "text-text-secondary",
      tertiary: "text-text-tertiary",
      link: "text-text-links",
      disabled: "text-text-disabled",
      links: "text-text-links",
    },
    font: {
      mono: "font-mono",
      sans: "font-sans",
    },
    noWrap: {
      // Ensure the parent container has a defined width for ellipsis to appear:
      // - In a flex container, the text will truncate automatically if there's not enough space.
      // - Otherwise, apply 'inline-block' to the <Text> component and set a fixed width on the parent container.
      true: "whitespace-nowrap overflow-hidden text-ellipsis block",
    },
    uppercase: {
      true: "uppercase",
    },
    weight: {
      bold: "font-weight-700",
      normal: "font-weight-400",
    },
    isClickable: {
      true: "cursor-pointer hover:underline",
    },
    size: {
      s: "text-s",
      m: "text-m",
      l: "text-l",
      xl: "text-xl",
      xxl: "text-xxl",
      fourXl: "text-4xl",
    },
  },
  defaultVariants: {
    variant: "smallRegular",
    weight: "normal",
  },
});

export const Text = fixedForwardRef(function Text<T extends React.ElementType>(
  {
    as: Component = "span",
    variant,
    color,
    className,
    children,
    weight,
    noWrap,
    uppercase,
    isClickable,
    size,
    font,
    ...props
  }: TextProps<T>,
  ref: React.Ref<T>
) {
  return (
    <Component
      className={twMerge(
        textStyles({
          variant,
          color,
          className,
          weight,
          noWrap,
          uppercase,
          isClickable,
          font,
          size,
        })
      )}
      {...props}
      ref={ref}
    >
      {children}
    </Component>
  );
});
