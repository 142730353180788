import { IconSize } from "ds-ui/icons";
import { TextVariant } from "ds-ui/molecules/Text";
import { type Size } from "./types";

export type AvailableIconSize = Extract<IconSize, "s" | "m" | "l">;
export type DsToken = {
  iconSize: AvailableIconSize;
  fontSize: TextVariant;
  separatorSize: AvailableIconSize;
};

const BREADCRUMBS_SIZE_TO_DS_TOKENS: Record<Size, DsToken> = {
  s: {
    iconSize: "s",
    separatorSize: "s",
    fontSize: "smallRegular",
  },
  m: {
    iconSize: "m",
    separatorSize: "m",
    fontSize: "mediumRegular",
  },
  l: {
    iconSize: "l",
    separatorSize: "l",
    fontSize: "largeMedium",
  },
};
export const getIconSize = (size: Size): AvailableIconSize =>
  BREADCRUMBS_SIZE_TO_DS_TOKENS[size].iconSize;
export const getTextVariant = (size: Size): TextVariant =>
  BREADCRUMBS_SIZE_TO_DS_TOKENS[size].fontSize;
export const getSeparatorSize = (size: Size): AvailableIconSize =>
  BREADCRUMBS_SIZE_TO_DS_TOKENS[size].separatorSize;
