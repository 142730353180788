import React from "react";
import { colors } from "ds-ui/generated/tailwind-variables";
import { commonComponentsSelectors } from "../constants";
import { Icon } from "../icons";
import { Button } from "./Button";
import { Text } from "./Text";
import { Loader } from "./Loader";

export interface LoaderInterface {
  noData?: boolean;
  noDataDetailsMessage?: string;

  noConfig?: boolean;
  noConfigTitle?: string;
  noConfigBtnLabel?: string;
  onConfigureClick?: () => void;

  isLoading?: boolean;
  loaderText?: string;
  loaderDetailsText?: string;
  errorMessage?: string | Error | null;
  centered?: boolean;
  onRefreshClick?: () => void;
  hideRetryButton?: boolean;
}

export const LoadingAndErrorDisplay = ({
  noData,
  centered = false,
  onRefreshClick,
  noDataDetailsMessage = "There are no event records for this duration. Please change the time filter or check policy or dataset configurations and try again.",
  noConfig,
  onConfigureClick,
  noConfigBtnLabel,
  errorMessage,
  isLoading,
  loaderText,
  loaderDetailsText,
  noConfigTitle,
  hideRetryButton,
}: LoaderInterface) => {
  return (
    <div
      style={
        centered
          ? {
              position: "relative",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }
          : {
              padding: 80,
            }
      }
      className="flex flex-col items-center grow justify-center"
    >
      {isLoading && <Loader text={loaderText} detailsText={loaderDetailsText} />}

      {(noData || noConfig || errorMessage) && !isLoading && (
        <div className="flex flex-col items-center">
          <Icon
            style={{ color: colors.semantic["informational-background"] }}
            size="xxxl"
            name="BarChartOutlined"
          />
          <Text className="mt-xs" color="secondary" variant="largeMedium">
            {noConfig ? noConfigTitle : (errorMessage && String(errorMessage)) || "Data not found"}
          </Text>

          {(errorMessage || noConfig) && !hideRetryButton && (
            <Button
              className="mt-s"
              weight="secondary"
              size="l"
              onClick={noConfig ? onConfigureClick : onRefreshClick}
              beforeIcon={<Icon name={noConfig ? "AddOutlined" : "RefreshOutlined"} />}
              testId={
                noConfig
                  ? commonComponentsSelectors.actions.configure
                  : commonComponentsSelectors.actions.reload
              }
            >
              {noConfig ? noConfigBtnLabel : "Try again"}
            </Button>
          )}
          {noData && !errorMessage && (
            <Text
              style={{ maxWidth: 280 }}
              size="m"
              color="secondary"
              className="mt-xs text-center"
            >
              {noDataDetailsMessage}
            </Text>
          )}
        </div>
      )}
    </div>
  );
};
