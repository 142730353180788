import React from "react";
import { Icon } from "ds-ui";
import { GridHeaderActionButton } from "./GridHeaderActionButton";

export function ColumnSortedDescendingIcon() {
  return (
    <GridHeaderActionButton active>
      <Icon name="ArrowDownward" size="s" />
    </GridHeaderActionButton>
  );
}
