import React from "react";
import { twMerge } from "tailwind-merge";

interface ModalContentProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export function ModalContent({ className, children, ...props }: ModalContentProps) {
  const classNames = twMerge("flex flex-col height-full justify-between gap-4", className);
  return (
    <div className={classNames} {...props}>
      {children}
    </div>
  );
}
