import React from "react";

interface ModalTitleProps {
  iconStart?: React.ReactNode;
  children: React.ReactNode;
}

export function ModalTitle({ iconStart, children }: ModalTitleProps) {
  return (
    <div className="flex items-center gap-2">
      {iconStart}
      {children}
    </div>
  );
}
