import { cva } from "class-variance-authority";

export const wrapperStyles = cva("inline-flex flex-col", {
  variants: {
    fullWidth: {
      true: "w-full",
      false: "w-[30ch]", // force width to 30 characters
    },
  },
  defaultVariants: {
    fullWidth: true,
  },
});

export const labelStyles = cva("text-s font-medium text-text-subtle", {
  variants: {
    disabled: {
      true: "text-text-subtle",
      false: "text-text-subtle",
    },
  },
  defaultVariants: {
    disabled: false,
  },
});

export const iconStyles = cva("flex items-center flex-shrink-0 ", {
  variants: {
    position: {
      left: "pl-3",
      right: "pr-3",
    },
    disabled: {
      true: "text-text-subtle",
      false: "text-text-subtle",
    },
  },
  defaultVariants: {
    disabled: false,
  },
});

export const inputContainerStyles = cva(
  "relative flex items-stretch rounded-xs focus-within:outline focus-within:outline-outlines-focus bg-background-base1 border border-solid",
  {
    variants: {
      error: {
        true: "outline outline-semantic-error-primary",
      },
      disabled: {
        true: "border-interactive-disabled",
        false: "hover:bg-background-base0  border-outlines-2",
      },
    },
    defaultVariants: {
      disabled: false,
    },
  }
);

export const inputStyles = cva(
  "w-full bg-inherit border-0 outline-none focus:outline-none placeholder-text-subtle align-top leading-m",
  {
    variants: {
      size: {
        l: "p-l",
        m: "p-m",
      },
      multiline: {
        true: "min-h-[120px]",
      },
      textAlign: {
        left: "text-left",
        center: "text-center",
        right: "text-right",
      },
      disabled: {
        true: "text-text-subtle",
        false: "text-text-primary",
      },
    },
    defaultVariants: {
      size: "m",
      disabled: false,
    },
  }
);

export const prefixStyles = cva(
  "flex-shrink-0 flex items-center border-0 border-r border-solid border-r-outlines-2 text-text-subtle",
  {
    variants: {
      styled: {
        true: "px-3 ",
      },
      disabled: {
        true: "pointer-events-none",
      },
    },
    defaultVariants: {
      disabled: false,
    },
  }
);

export const suffixStyles = cva(
  "flex-shrink-0 flex items-center border-0 border-l border-solid border-l-outlines-2 text-text-subtle",
  {
    variants: {
      styled: {
        true: "px-3",
      },
      disabled: {
        true: "pointer-events-none",
      },
    },
    defaultVariants: {
      disabled: false,
    },
  }
);
