import { useUpdateUserPreferences, useUserPreferences } from "./user-preferences/userPreferences";

export function useHomeRouteSetting() {
  const { data, isLoading } = useUserPreferences();
  const homeRoute = data?.settings?.homeRoute;

  const { mutateAsync: updateUserPreferences } = useUpdateUserPreferences();

  const updateHomeRoute = async (homeRoute: string) => {
    await updateUserPreferences({ settings: { ...data?.settings, homeRoute } });
  };

  return {
    homeRoute: homeRoute ?? "/overview",
    updateHomeRoute,
    isLoading,
  };
}
