import React, { useEffect, useMemo, useState } from "react";
import { isEqual, keyBy } from "lodash";
import { VisibleEntitiesSettings } from "shared/ui-settings/VisibleEntitiesSettings";
import { GridColumnVisibilityModel } from "@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces";
import { useGridApiContext } from "@mui/x-data-grid";
import {
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  gridColumnDefinitionsSelector,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import { useGrid } from "../useGrid";
import { CHECKBOX_COLUMN_ID } from "../constants";
import { CHGridColDef } from "../types";

export function GridCustomization({ TriggerComponent }: { TriggerComponent?: React.ElementType }) {
  const {
    columnVisibilityModel,
    setColumnVisibilityModel,
    columnsOrder,
    setColumnsOrder,
    defaultColumnsSettings,
    pinnedColumns,
    noDataFields,
  } = useGrid();
  const apiRef = useGridApiContext();
  const columns = useGridSelector(apiRef, gridColumnDefinitionsSelector) as CHGridColDef[];

  const columnsMap = useMemo(() => {
    return keyBy(columns, "field");
  }, [columns]);

  const isPinned = (column: CHGridColDef) =>
    pinnedColumns.left?.includes(column.field) || pinnedColumns.right?.includes(column.field);

  const allColumns = useMemo(
    () =>
      columnsOrder
        .map((id) => columns.find((col) => col.field === id)!)
        .filter((col) => col && col.field !== "actions")
        .map((column) => ({ id: column.field, name: column.headerName! })),
    [columns]
  );
  const allColumnsIds = useMemo(() => allColumns.map((column) => column.id), [allColumns]);
  const alwaysVisibleColumnsIds = useMemo(
    () =>
      columns
        .filter((column) => column.hideable === false || isPinned(column))
        .map((column) => column.field),
    [columns, pinnedColumns]
  );

  const [visibleColumnsIds, setVisibleColumnsIds] = useState(() =>
    getVisibleColumns(allColumnsIds, columnVisibilityModel)
  );
  const [orderedColumnsIds, setOrderedColumnsIds] = useState(allColumnsIds);
  const [isEditing, setIsEditing] = useState(false);

  const columnSettings = useMemo(
    () =>
      orderedColumnsIds
        // Some grids have differing columns based on some conditions.
        // This filter eliminates those that are not visible right now.
        .filter((id) => columnsMap[id])
        .map((id) => {
          const column = columnsMap[id];
          return {
            id: column.field,
            visible: visibleColumnsIds.includes(column.field),
            name: column.headerName!,
          };
        }),
    [columnsMap, orderedColumnsIds, visibleColumnsIds]
  );

  const isPristine = useMemo(() => {
    const currentVisibleColumns = allColumnsIds.filter((id) => columnVisibilityModel[id] !== false);

    return (
      isEqual(currentVisibleColumns, visibleColumnsIds) && isEqual(orderedColumnsIds, allColumnsIds)
    );
  }, [visibleColumnsIds, allColumnsIds, columnVisibilityModel]);

  useEffect(() => {
    if (!isEditing) {
      setOrderedColumnsIds(allColumnsIds);
    }
  }, [allColumnsIds, isEditing]);

  useEffect(() => {
    if (!isEditing) {
      setVisibleColumnsIds(getVisibleColumns(allColumnsIds, columnVisibilityModel));
    }
  }, [columnVisibilityModel, isEditing]);

  const handleSave = () => {
    setColumnsOrder([...orderedColumnsIds, "actions"]);
    const newVisibilityModel = allColumnsIds.reduce(
      (model: GridColumnVisibilityModel, id) => ({
        ...model,
        [id]: visibleColumnsIds.includes(id),
      }),
      {}
    );

    setColumnVisibilityModel({
      ...newVisibilityModel,
    });
  };

  return (
    <VisibleEntitiesSettings
      allEntities={allColumns}
      defaultColumnsSettings={defaultColumnsSettings}
      alwaysEnabledEntities={alwaysVisibleColumnsIds}
      hiddenEntities={[CHECKBOX_COLUMN_ID, GRID_DETAIL_PANEL_TOGGLE_FIELD]}
      columnsSettings={columnSettings}
      selectedHasNotChanged={isPristine}
      noDataFields={noDataFields}
      showSelectAll
      onCancel={() => {
        setVisibleColumnsIds(getVisibleColumns(allColumnsIds, columnVisibilityModel));
        setOrderedColumnsIds(allColumnsIds);
      }}
      onChange={(visibleColumns) => {
        setIsEditing(true);
        setVisibleColumnsIds(visibleColumns.filter((c) => c.visible).map((c) => c.id));
        setOrderedColumnsIds(visibleColumns.map((c) => c.id));
      }}
      onSave={handleSave}
      TriggerComponent={TriggerComponent}
    />
  );
}

const getVisibleColumns = (
  columnsIds: string[],
  columnVisibilityModel: GridColumnVisibilityModel
): string[] => {
  return columnsIds.filter((id) => !(id in columnVisibilityModel) || columnVisibilityModel[id]);
};
