import React from "react";
import { useMemoize } from "libs/hooks/useMemoize";
import { TruncateFilters } from "ui/molecules/TruncateFilters";
import { ConfigItem } from "./useFilters.type";

export type FiltersProps<Key extends string> = {
  filters: Record<Key, ConfigItem>;
  children: React.ReactNode;
  resetAll: any;
  lastActive: string;
  showClearAll?: boolean;
  start?: React.ReactNode;
  end?: React.ReactNode;
  fixedSize?: number;
  showPrefix?: boolean;
  hideWhenNotFiltered?: boolean;
  customPrefix?: React.ReactNode;
};

export function Filters<Key extends string>({
  filters,
  children,
  resetAll,
  showClearAll,
  lastActive,
  start,
  end,
  fixedSize,
  showPrefix = true,
  hideWhenNotFiltered = false,
  customPrefix,
}: FiltersProps<Key>) {
  const { nodes, isAllDefault } = useMemoize(computeFilters, [
    filters,
    children as any,
    lastActive,
  ]);

  if (hideWhenNotFiltered && isAllDefault) {
    return null;
  }

  return (
    <TruncateFilters
      showPrefix={showPrefix}
      fixedElements={fixedSize}
      hideClearAll={isAllDefault && !showClearAll}
      clearAll={resetAll}
      customPrefix={customPrefix}
    >
      {start}
      {nodes}
      {end}
    </TruncateFilters>
  );
}

// Just connects chip components with filters state to create stateful chip comnonents
export function computeFilters(
  filters: any,
  chips: React.ReactElement[],
  lastActiveName: string | null
) {
  const children = React.Children.toArray(chips) as React.ReactElement[];
  const chipsMaps = Object.fromEntries(children.map((el) => [el.props.name, el]));

  const items: string[] = [];
  for (const node of children) {
    if (!node || !node?.props?.name) {
      continue;
    }
    const name = node.props.name;
    const config = filters[name];

    if (!config.isZero || node?.props?.showEmpty) {
      items.push(name);
    }
  }

  const filterNodeItems = items.map((key) => {
    const chipNode = chipsMaps[key];
    const filterItem = filters[key];
    if ((filterItem.isZero && !chipNode?.props?.showEmpty) || !chipNode) {
      return null;
    }
    return React.cloneElement(chipNode, {
      key,
      name: key,
      isDefault: filterItem.isDefault,
      isZero: filterItem.isZero,
      value: filterItem.value,
      setValue: filterItem.setValue,
      isClearAll: filterItem.isClearAll,
      context: filters,
      animateMount: lastActiveName === key,
      onDelete: filterItem.reset,
    });
  });

  const isAllDefault = filterNodeItems.filter(Boolean).every((el: any) => {
    if (!el.props.isDefault && el.props.isClearAll) {
      return el.props.isZero;
    }
    return true;
  });
  return { nodes: filterNodeItems, isAllDefault };
}
