import React from "react";
import { VariantProps } from "class-variance-authority";
import { twMerge } from "tailwind-merge";
import { fixedForwardRef } from "shared";
import { iconSizesKeys } from "ds-ui/icons/Icon";
import { Button as MUIButton, ButtonProps as MUIButtonProps } from "@mui/material";
import { ButtonStyles } from "./Button.styles";
import { Spinner } from "./Spinner";

export type ButtonProps = Pick<
  MUIButtonProps,
  "id" | "href" | "children" | "onClick" | "title" | "style" | "component" | "type"
> &
  VariantProps<typeof ButtonStyles> & {
    className?: string;
    beforeIcon?: React.ReactElement;
    afterIcon?: React.ReactElement;
    // TODO: testId should not be mandatory,
    //  in fact we don't need a test id on buttons as these can be targeted by the role
    testId: string;
    loading?: boolean;
    loadingPlaceholder?: React.ReactNode;
  };

export const Button = fixedForwardRef(function CHButton(
  {
    weight = "primary",
    className,
    title,
    tone = "positive",
    size,
    disabled,
    beforeIcon,
    afterIcon,
    testId,
    loading,
    loadingPlaceholder,
    ...props
  }: ButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const getStartingNode = () => {
    if (loading) {
      return <Spinner className={twMerge("flex text-current", size === "s" ? "mr-xs" : "mr-s")} />;
    }

    if (beforeIcon) {
      return (
        <div className={"flex " + (size === "s" ? "mr-xs" : "mr-s")}>
          {React.cloneElement(beforeIcon, {
            // Conditionally add the `size` prop only if it's not already specified
            ...(beforeIcon.props.size === undefined && {
              size: size === "l" ? iconSizesKeys.l : iconSizesKeys.s,
            }),
          })}
        </div>
      );
    }

    return null;
  };

  return (
    <MUIButton
      ref={ref}
      disableRipple
      disabled={Boolean(disabled)}
      sx={{ border: "transparent" }}
      data-testid={testId}
      className={twMerge(ButtonStyles({ weight, className, tone, size, disabled: disabled }))}
      {...props}
    >
      {getStartingNode()}
      {loading ? loadingPlaceholder ?? props.children : props.children}
      {afterIcon && (
        <div className={"flex " + (size === "s" ? "ml-xs" : "ml-s")}>
          {React.cloneElement(afterIcon, {
            // Conditionally add the `size` prop only if it's not already specified
            ...(afterIcon.props.size === undefined && {
              size: size === "l" ? iconSizesKeys.l : iconSizesKeys.s,
            }),
          })}
        </div>
      )}
    </MUIButton>
  );
});
