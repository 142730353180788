import { cva } from "class-variance-authority";
import React from "react";

const headerButtonClasses = cva(
  "h-6 w-6 flex items-center shrink-0 justify-center cursor-pointer rounded-xs text-interactive-positive border-none",
  {
    variants: {
      active: {
        true: "[&_svg]:!block outline outline-2 outline-offset-1 outline-outlines-focus bg-background-active",
        false: "hover:bg-background-active bg-transparent",
      },
    },
    defaultVariants: { active: false },
  }
);
interface GridHeaderActionButtonProps {
  active?: boolean;
  children: React.ReactNode;
  onClick?: React.MouseEventHandler;
  className?: string;
}

export function GridHeaderActionButton({
  active,
  children,
  onClick,
  className,
}: GridHeaderActionButtonProps) {
  return (
    <button className={headerButtonClasses({ className, active })} onClick={onClick}>
      {children}
    </button>
  );
}
