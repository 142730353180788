import React, { ElementType, useEffect, useRef, useState } from "react";
import { Merge } from "ts-essentials";
import { partition } from "lodash";
import { Popover, Fade } from "@mui/material";
import { StackInner } from "libs/layouts";
import { theme } from "ui/theme";
import { HStack, VStack, Box } from "ui";
import { types } from "api";
import { colors, space } from "ui/vars";
import { Item } from "libs/selection/collection/Item";
import { TreeProps, useTreeState } from "libs/selection/useTreeState";
import { selectors } from "libs/css";
import { css } from "ui/css";
import { Collection, Node } from "libs/selection/types";
import { Sensitivity } from "ui/types";
import { Text } from "../../atoms";
import { ListBox, PreviewListBox } from "./ListBox";

export const InputWrapper = React.forwardRef(function InputWrapper(
  { children, disabled, ...rest }: any,
  ref
) {
  return (
    <Box
      ref={ref}
      {...rest}
      sx={{
        border: `1px solid ${theme.palette.divider}`,
        background: colors.light120,
        [selectors.touch]: disabled
          ? undefined
          : {
              background: "white",
              borderColor: colors.blue100,
            },
        [selectors.errorGroup]: {
          borderColor: "#FF1212",
        },
      }}
    >
      {children}
    </Box>
  );
});
function useMultiSelect<T>({
  value,
  onChange,
  options,
  parentNotSelectable,
  defaultExpandedKeys,
  hideMissing,
  ...rest
}: {
  children: TreeProps<T>["children"];
  value: any[];
  options: Option[];
  parentNotSelectable?: boolean;
  hideMissing?: boolean;
  defaultExpandedKeys?: string[];
  onChange: (values: string[]) => void;
}) {
  const targetRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const allKeys = options.map((option) => option.value);
  const [selected, missing] = partition(
    value || [],
    (item) => !hideMissing || allKeys.includes(item) || ["all", "a", "l"].includes(item)
  );
  const state = useTreeState({
    selectionMode: "multiple",
    selectedKeys: selected,
    defaultExpandedKeys: defaultExpandedKeys ?? options.map((el) => el.value),
    onSelectionChange: (v) => onChange([...v, ...missing] as string[]),
    items: options as any[],
    parentNotSelectable,
    ...rest,
  });
  return {
    state,
    isOpen,
    selectedItems: [...state.selectionManager.selectedKeys],
    isSelected: (v: string) => {
      return state.selectionManager.isSelected(v);
    },
    getMenuProps() {
      const rect = targetRef.current?.getBoundingClientRect();
      return {
        PaperProps: {
          style: {
            minWidth: Math.max(rect?.width ?? 0, 200),
            maxWidth: Math.max(rect?.width ?? 100, 300),
            minHeight: 35,
          },
        },
        anchorEl: targetRef.current,
        open: isOpen,
        onClose: () => {
          setIsOpen(false);
        },
      };
    },
    getButtonProps() {
      return {
        ref: targetRef,
        onClick: () => {
          setIsOpen(true);
        },
      };
    },
  };
}

type Option = {
  label: string;
  value: string;
  icon?: JSX.Element;
  children?: Option[];
  // ugly hack to determine if category is selected, all child start with common prefix so we just check if we have this prefix
  childrenKey?: string;
};

function mapChildrenDefault(option: Option) {
  return (
    <Item
      textValue={option.label}
      key={option.value}
      title={
        <>
          {option.icon && React.cloneElement(option.icon, { fontSize: "small" })}
          <Text>{option.label}</Text>
        </>
      }
      hasChildItems={!!option.children}
      childItems={option.children}
    >
      {option.label}
    </Item>
  );
}

type MultiSelectProps = Merge<
  JSX.IntrinsicElements["input"],
  {
    children?: TreeProps<any>["children"];
    value: string[];
    options: Option[];
    onChange: (values: string[]) => void;
    messages?: {
      noneSelected?: string;
    };
    quantityCallback?: React.Dispatch<any>;
    selectAllName?: string;
    hasRootShowMore?: boolean | number;
    // unmask select for fullstory
    unmaskFS?: boolean;
    noneSelectedContent?: React.ReactNode;
    // hide options that are missing from options list and do not change their selection
    hideMissing?: boolean;
    ItemContentComponent?: ElementType<{ item: Node<any> }>;
    SelectedContentComponent?: ElementType<{
      selectedItems: React.Key[];
      disabled?: boolean;
      collection: Collection<
        Node<{
          dataset_sensitivities: Sensitivity[];
          label: string;
          selection_type: types.SelectionType;
          value: string;
        }>
      >;
    }>;
  }
>;

const previewListBoxStyles = css({
  "&:empty": {
    display: "none",
  },
});

export function MultiSelect({
  value,
  options: initialOptions,
  onChange,
  messages = {
    noneSelected: "Click to select",
  },
  hasRootShowMore,
  children = mapChildrenDefault,
  unmaskFS,
  ItemContentComponent,
  quantityCallback,
  selectAllName,
  SelectedContentComponent,
  noneSelectedContent,
  disabled,
  ...rest
}: MultiSelectProps) {
  if (selectAllName) {
    const selectAllOption = { value: "all", label: `Select all ${selectAllName}` };
    initialOptions = [selectAllOption, ...initialOptions];
  }
  const { selectedItems, state, isSelected, getMenuProps, getButtonProps } = useMultiSelect({
    value,
    options: initialOptions,
    onChange,
    children,
    ...rest,
  });
  const filteredSelectedItems = selectedItems.filter((item) => item && item !== "all");

  useEffect(() => {
    if (quantityCallback) {
      quantityCallback(filteredSelectedItems.length);
    }
  }, [filteredSelectedItems.length]);

  const spacing = 3;
  const buttonProps: any = { ...getButtonProps() };
  if (disabled) {
    // @ts-ignore
    buttonProps.onClick = undefined;
  }

  return (
    <div {...rest} aria-label="Multioption" data-fs={unmaskFS ? "unmask" : "mask"}>
      <InputWrapper disabled={disabled} {...buttonProps}>
        <StackInner space={spacing}>
          {!SelectedContentComponent && (
            <PreviewListBox
              ItemContentComponent={ItemContentComponent}
              hasRootShowMore={hasRootShowMore}
              className={previewListBoxStyles().className}
              state={state}
              disabled={disabled}
              // todo handle parent/child selection in selection manager
              filter={(v) => {
                return (
                  v.key !== "all" &&
                  (isSelected(v.key as any) ||
                    (v.hasChildNodes &&
                      !![...v.childNodes].find((el) => isSelected(el.key as any))))
                );
              }}
            />
          )}
          {SelectedContentComponent && (
            <SelectedContentComponent
              disabled={disabled}
              collection={state.collection}
              selectedItems={filteredSelectedItems}
            />
          )}
          {filteredSelectedItems.length === 0 &&
            (noneSelectedContent || (
              <HStack
                space="1"
                sx={{
                  paddingX: space[2],
                  minHeight: 34,
                }}
                align="center"
                key="none-selected"
              >
                <Text color={colors.bodySecondary}>{messages.noneSelected}</Text>
              </HStack>
            ))}
        </StackInner>
      </InputWrapper>
      <Popover
        {...getMenuProps()}
        marginThreshold={25}
        anchorOrigin={{
          vertical: 0,
          horizontal: "left",
        }}
        TransitionComponent={Fade}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <VStack
          // forward unmask to this vstack bacause data attr is not propagated to portals
          data-fs={unmaskFS ? "unmask" : "mask"}
          space="0"
          sx={{
            border: colors.blue100,
            maxHeight: 500,
            overflow: "auto",
          }}
        >
          <ListBox ItemContentComponent={ItemContentComponent} state={state} />
        </VStack>
      </Popover>
    </div>
  );
}
