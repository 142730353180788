import React from "react";
import { CHIcon4 } from "assets/icons";
import { ENV } from "runenv";
import { navigationSelectors } from "shared/testConstants";
import { useAvailableNavigationItems } from "shared/useAvailableNavigationItems";
import { HOME_PAGE_FALLBACK_PATH } from "app/routesPaths";
import { Link } from "react-router-dom";
import { logout } from "modules/auth";
import { IUser } from "api/auth/interfaces";
import { Box } from "ui";
import { BetaLabel } from "../atoms";
import { messages } from "../messages";
import { useProfileMenu, ProfileMenuItem, NavigationItem } from "../molecules";

function SidebarTemplate({ homePath, children }: { homePath: string; children: React.ReactNode }) {
  return (
    <div
      className="flex flex-col flex-grow bg-background-base3 border border-bottom border-solid border-outlines-1"
      data-color-scheme="dark"
      data-support-dark-theme="true"
    >
      <Box
        data-testid={navigationSelectors.home}
        as={Link}
        to={homePath}
        className="flex place-self-center relative mb-3 pt-3"
      >
        <CHIcon4 />
        {ENV.IS_BETA_ENV && <BetaLabel data-fs="unmask">{messages.betaLabel}</BetaLabel>}
      </Box>
      <div className="flex flex-col flex-grow gap-0">{children}</div>
      {/* Space for Intercom */}
      <div className="h-[50px]" />
    </div>
  );
}

export const Sidebar = React.memo(function Sidebar({ user }: { user: IUser }) {
  const { availableItems: links, homeItem } = useAvailableNavigationItems();
  const [modal, api] = useProfileMenu({
    logout: logout,
    user: user,
  });

  return (
    <SidebarTemplate homePath={homeItem?.path || HOME_PAGE_FALLBACK_PATH}>
      {modal}
      {links?.map((item, i) => (
        <React.Fragment key={i}>
          {!item.isSpacer ? <NavigationItem {...item} /> : <div style={{ flexGrow: 1 }} />}
        </React.Fragment>
      ))}
      <ProfileMenuItem
        onClick={(e: any) => api.onOpen(e.target)}
        data-testid={navigationSelectors.profile}
      >
        {user.email}
      </ProfileMenuItem>
    </SidebarTemplate>
  );
});
