import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import classnames from "classnames";

const MARGIN_OF_ERROR = 5; // px

export interface LimitedListProps<T> {
  items: T[];
  rowsLimit: number;
  gapSize: number;
  renderItem: (item: T) => JSX.Element;
  renderMore: (itemsNotRendered: T[]) => JSX.Element;
  className?: string;
}

export function LimitedList<T>({
  items,
  rowsLimit = 2,
  gapSize = 8,
  renderItem,
  renderMore,
  className,
}: LimitedListProps<T>) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isCalculating, setIsCalculating] = useState<boolean>(true);
  const [visibleItemsCount, setVisibleItemsCount] = useState<number>(items?.length || 0);
  const [showMore, setShowMore] = useState<boolean>(items.length > 0);

  const width = containerRef.current?.parentElement?.offsetWidth || 0;

  const listClassNames = classnames(
    "flex flex-row flex-wrap items-center h-fit-content",
    className
  );

  const reset = () => {
    setIsCalculating(items.length > 0);
    setVisibleItemsCount(items?.length || 0);
    setShowMore(items.length > 0);
  };

  useEffect(() => {
    window.addEventListener("resize", reset);

    return () => window.removeEventListener("resize", reset);
  }, []);

  useEffect(() => {
    reset();
  }, [rowsLimit, items]);

  useLayoutEffect(() => {
    if (!isCalculating) {
      return;
    }

    const children = containerRef.current?.children;
    const childrenArr = Array.from(children || []);

    const moreButtonWidth = childrenArr.pop()?.clientWidth || 0;

    let currentRowWidth = 0;
    let currentRowNumber = 1;
    let isLastRow = rowsLimit === 1;
    for (let i = 0; i < childrenArr.length; i++) {
      const child = childrenArr[i] as HTMLElement;
      const childWidth = child.clientWidth;
      const needsMoreButton = i < items.length - 1;

      currentRowWidth += (currentRowWidth ? gapSize : 0) + childWidth;

      const currentRowWidthIfLastVisibleItem =
        currentRowWidth + (needsMoreButton && isLastRow ? gapSize + moreButtonWidth : 0);

      if (currentRowWidthIfLastVisibleItem + MARGIN_OF_ERROR > width) {
        if (isLastRow) {
          setShowMore(i - 1 < items.length - 1);
          setVisibleItemsCount(i);
          setIsCalculating(false);

          return;
        }

        ++currentRowNumber;
        currentRowWidth = childWidth;
        isLastRow = currentRowNumber === rowsLimit;
      }
    }

    setShowMore(false);
    setVisibleItemsCount(items.length);
    setIsCalculating(false);
  }, [items, rowsLimit, isCalculating, setIsCalculating, setVisibleItemsCount, setShowMore]);

  if (!items?.length) {
    return null;
  }

  return (
    <div
      className={listClassNames}
      ref={containerRef}
      style={{
        width,
        position: isCalculating ? "absolute" : "relative",
        left: isCalculating ? 1000000 : undefined,
      }}
    >
      {items.slice(0, visibleItemsCount).map((item, index) => (
        <div key={index}>{renderItem(item)}</div>
      ))}
      {showMore && <>{renderMore(items.slice(isCalculating ? 1 : visibleItemsCount))}</>}
    </div>
  );
}
