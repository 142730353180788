import React from "react";
import { CircularProgress } from "@mui/material";

interface SpinnerProps {
  className?: string;
}

export function Spinner({ className }: SpinnerProps) {
  return <CircularProgress size={24} thickness={6} className={className} />;
}
