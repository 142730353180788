import React from "react";
import { cva } from "class-variance-authority";
import MuiTab, { TabProps } from "@mui/material/Tab";

const styles = cva("text-l aria-selected:text-interactive-positive normal-case", {
  variants: {
    disabled: {
      true: "text-text-disabled",
      false: "text-text-secondary",
    },
  },
  defaultVariants: { disabled: false },
});

export function Tab(props: TabProps) {
  const className = styles({ disabled: !!props.disabled, className: props.className });

  return <MuiTab {...props} className={className} disableFocusRipple disableTouchRipple />;
}
