import React, { forwardRef } from "react";
import { Link, LinkProps } from "@mui/material";
import { Box } from "ui/atoms/Box";
import { selectors } from "libs/css";

type LinkButtonProps = LinkProps & {
  hasTruncation?: boolean;
  bold?: boolean;
  disabled?: boolean;
};
export const LinkButton = forwardRef(function LinkButton(
  { bold, hasTruncation, children, color, disabled, ...props }: LinkButtonProps,
  ref
) {
  return (
    <Box
      ref={ref as any}
      as={Link}
      style={
        hasTruncation
          ? {
              ...(bold ? { fontWeight: 500 } : {}),
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }
          : {
              ...(bold ? { fontWeight: 500 } : {}),
              whiteSpace: "nowrap",
            }
      }
      sx={{
        underline: "hover",
        color: color || "inherit",
        ...(disabled
          ? {
              cursor: "default",
            }
          : {
              [selectors.touchGroup]: {
                textDecoration: "underline",
              },
              [selectors.touch]: { fontWeight: 500 },
            }),
      }}
      title={children?.toString()}
      underline={disabled ? "none" : "hover"}
      {...props}
      onClick={disabled ? undefined : props.onClick}
    >
      {children}
    </Box>
  );
});
